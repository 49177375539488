import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PlcCommonModule } from '@components/modules/common/common.module';
import { PlcFormsModule } from '@components/modules/forms/forms.module';
import { PlcGameCommonsModule } from '@components/modules/game/commons/game-commons.module';
import { PlcLayoutModule } from '@components/modules/layout/layout.module';
import { CoreModule } from '@core/core.module';
import { LetDirective } from '@ngrx/component';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		LetDirective,
		CoreModule,
		ReactiveFormsModule,
		FormsModule,
		PlcLayoutModule,
		PlcGameCommonsModule,
		PlcCommonModule,
		PlcFormsModule,
		LetDirective,
	],
	exports: [
		CoreModule,
		LetDirective,
		ReactiveFormsModule,
		FormsModule,
		PlcLayoutModule,
		PlcGameCommonsModule,
		PlcCommonModule,
		PlcFormsModule,
		LetDirective,
	],
})
export class WebSharedModule {}
